import { useQuery } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { AquathlonEntry } from 'src/lyg/entities/entries/aquathlonEntry';
import { AthleticsEntry } from 'src/lyg/entities/entries/athleticsEntry';
import { BmxEntry } from 'src/lyg/entities/entries/bmxEntry';
import { LYGEntry } from 'src/lyg/entities/entries/entry';
import { IndoorRowingEntry } from 'src/lyg/entities/entries/indoorRowingEntry';
import { JudoEntry } from 'src/lyg/entities/entries/judoEntry';
import { KayakSlalomEntry } from 'src/lyg/entities/entries/kayakSlalomEntry';
import { KayakSprintEntry } from 'src/lyg/entities/entries/kayakSprintEntry';
import { PgAthleticsEntry } from 'src/lyg/entities/entries/pgAthleticsEntry';
import { PgSwimmingEntry } from 'src/lyg/entities/entries/pgSwimmingEntry';
import { SwimmingEntry } from 'src/lyg/entities/entries/swimmingEntry';
import { TennisEntry } from 'src/lyg/entities/entries/tennisEntry';
import { TrampolineEntry } from 'src/lyg/entities/entries/trampolineEntry';
import { LYG_ENTRIES_KEYS } from 'src/lyg/pages/entries/queryKeys';
import { ApiError } from 'src/lyg/utils/apiError';
import { apiClient } from 'src/lyg/utils/apiClient';
import { PaginatedApiResponse } from 'src/shared/utils/types';
import { LYG_COMPETITION_CATEGORY_TYPE } from 'src/lyg/entities/competitions';
import { LYG_EVENT_TYPE } from 'src/lyg/entities/events';
import { dateToISONoTimezone } from 'src/shared/utils/dates';
import { CrossCountryEntry } from 'src/lyg/entities/entries/crossCountryEntry';
import { RoadCyclingEntry } from 'src/lyg/entities/entries/roadCyclingEntry';
import { SwimmingRecreationalEntry } from 'src/lyg/entities/entries/swimmingRecreationalEntry';

interface QueryFilters {
  sportId?: number | null;
  competitionId?: number | null;
  search?: string | null;
  eventType?: LYG_EVENT_TYPE | null;
  page: number;
  itemsPerPage?: number;
  categoryType?: LYG_COMPETITION_CATEGORY_TYPE | null;
  boroughId?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
  ageGroup?: string | null;
  noPagination?: number;
}

export interface EntryApiResponse {
  lyg_entry_id: number;
  lyg_name: string;
  lyg_gender: string;
  lyg_email: string | null;
  lyg_parent_name: string | null;
  lyg_parent_email: string | null;
  lyg_borough_id: number;
  lyg_borough_name: string;
  lyg_dob: string;
  lyg_ethnicity: string;
  lyg_disability: string;
  lyg_home_postcode: string;
  lyg_school: string;
  lyg_emergency_contact_name: string;
  lyg_emergency_contact_number: string;
  lyg_imagery_consent: 1 | 0;
  lyg_competition_name: string | null;
  lyg_aquathlon_reserve: 1 | 0 | null;
  lyg_athletics_track_event: string | null;
  lyg_athletics_field_event: string | null;
  lyg_athletics_four_times_one_hundred_meters_relay: 1 | 0 | null;
  lyg_bmx_license_number: string | null;
  lyg_bmx_grade: string | null;
  lyg_bmx_reserve: 1 | 0 | null;
  lyg_indoor_rowing_reserve: 1 | 0 | null;
  lyg_indoor_rowing_year_group: string | null;
  lyg_judo_license_number: string | null;
  lyg_judo_weight: number | null;
  lyg_judo_dan_grade: string | null;
  lyg_judo_belt_colour: string | null;
  lyg_judo_section: string | null;
  lyg_judo_boys_section_a_weight: string | null;
  lyg_judo_boys_section_b_weight: string | null;
  lyg_judo_boys_section_c_weight: string | null;
  lyg_judo_girls_section_a_weight: string | null;
  lyg_judo_girls_section_b_weight: string | null;
  lyg_judo_girls_section_c_weight: string | null;
  lyg_kayak_slalom_seniority: string | null;
  lyg_kayak_sprint_age_category: string | null;
  lyg_kayak_sprint_reserve: 1 | 0 | null;
  lyg_pg_athletics_disability_category: string | null;
  lyg_pg_athletics_event_1: string | null;
  lyg_pg_athletics_event_2: string | null;
  lyg_pg_athletics_four_times_one_hundred_meters_relay: 1 | 0 | null;
  lyg_pg_swimming_reserve: 1 | 0 | null;
  lyg_pg_swimming_four_times_twenty_five_meters_freestyle: 1 | 0 | null;
  lyg_pg_swimming_four_times_twenty_five_meters_backstroke: 1 | 0 | null;
  lyg_pg_swimming_event: string | null;
  lyg_swimming_reserve: 1 | 0 | null;
  lyg_swimming_membership_number: string | null;
  lyg_swimming_entry_time: number | null;
  lyg_swimming_entry_time_course: string | null;
  lyg_swimming_competition_event: string | null;
  lyg_swimming_para_swimming_classification: string | null;
  lyg_swimming_event: string | null;
  swimming_four_times_fifty_meters_medley: 1 | 0 | null;
  swimming_four_times_fifty_meters_freestyle: 1 | 0 | null;
  lyg_tennis_membership_number: string | null;
  lyg_tennis_itf_world_tennis_number: string | null;
  lyg_tennis_seniority: string | null;
  lyg_trampoline_reserve: 1 | 0 | null;
  lyg_trampoline_tier: string | null;
  lyg_trampoline_membership_number: string | null;
  lyg_road_cycling_membership_number: string | null;
  lyg_road_cycling_time_trial_competitor: 1 | 0 | null;
  lyg_road_cycling_time_trial_category: string | null;
  lyg_road_cycling_reserve: 1 | 0 | null;
  lyg_road_cycling_road_race_competitor: 1 | 0 | null;
  lyg_cross_country_event_age_category: string | null;
  lyg_swimming_recreational_event_number: number | null;
  lyg_swimming_recreational_event: string | null;
  lyg_swimming_recreational_age_group: string | null;
  lyg_swimming_recreational_event_sex: string | null;
  lyg_swimming_recreational_level: string | null;
  lyg_swimming_competitive_event_number: number | null;
  lyg_swimming_competitive_event: string | null;
  lyg_swimming_competitive_age_group: string | null;
  lyg_swimming_competitive_event_sex: string | null;
  lyg_swimming_competitive_level: string | null;
  lyg_swimming_competitive_entry_time: string | null;
  lyg_swimming_competitive_entry_time_course: string | null;
  lyg_swimming_competitive_registration_number: string | null;
}

export function mapEntriesResponseToEntries(entries: EntryApiResponse[]): LYGEntry[] {
  return entries.map(
    (entry: EntryApiResponse) =>
      new LYGEntry({
        id: entry.lyg_entry_id,
        name: entry.lyg_name,
        gender: entry.lyg_gender,
        email: entry.lyg_email,
        parentName: entry.lyg_parent_name,
        parentEmail: entry.lyg_parent_email,
        ethnicity: entry.lyg_ethnicity,
        disability: entry.lyg_disability,
        boroughId: entry.lyg_borough_id,
        boroughName: entry.lyg_borough_name,
        homePostcode: entry.lyg_home_postcode,
        school: entry.lyg_school,
        emergencyContactName: entry.lyg_emergency_contact_name,
        emergencyContactNumber: entry.lyg_emergency_contact_number,
        dob: new Date(entry.lyg_dob),
        imageryConscent: Boolean(entry.lyg_imagery_consent),
        competitionName: entry.lyg_competition_name,
        aquathlon:
          entry.lyg_aquathlon_reserve !== null
            ? new AquathlonEntry({ reserve: Boolean(entry.lyg_aquathlon_reserve) })
            : null,
        athletics:
          entry.lyg_athletics_field_event && entry.lyg_athletics_track_event
            ? new AthleticsEntry({
                fieldEvent: entry.lyg_athletics_field_event,
                fourTimesOneHundredMetersRelay: Boolean(entry.lyg_athletics_four_times_one_hundred_meters_relay),
                trackEvent: entry.lyg_athletics_track_event,
              })
            : null,
        bmx:
          entry.lyg_bmx_grade && entry.lyg_bmx_license_number
            ? new BmxEntry({
                grade: entry.lyg_bmx_grade,
                licenseNumber: entry.lyg_bmx_license_number,
                reserve: Boolean(entry.lyg_bmx_reserve),
              })
            : null,
        indoorRowing:
          entry.lyg_indoor_rowing_reserve !== null && entry.lyg_indoor_rowing_year_group
            ? new IndoorRowingEntry({
                reserve: Boolean(entry.lyg_indoor_rowing_reserve),
                categoryAgeGroup: entry.lyg_indoor_rowing_year_group,
              })
            : null,
        judo:
          entry.lyg_judo_dan_grade && entry.lyg_judo_license_number && entry.lyg_judo_section
            ? new JudoEntry({
                danGrade: entry.lyg_judo_dan_grade,
                licenseNumber: entry.lyg_judo_license_number,
                section: entry.lyg_judo_section,
                weightBoysSectionA: entry.lyg_judo_boys_section_a_weight,
                weightBoysSectionB: entry.lyg_judo_boys_section_b_weight,
                weightBoysSectionC: entry.lyg_judo_boys_section_c_weight,
                weightGirlsSectionA: entry.lyg_judo_girls_section_a_weight,
                weightGirlsSectionB: entry.lyg_judo_girls_section_b_weight,
                weightGirlsSectionC: entry.lyg_judo_girls_section_c_weight,
              })
            : null,
        kayakSlalom: entry.lyg_kayak_slalom_seniority
          ? new KayakSlalomEntry({ seniority: entry.lyg_kayak_slalom_seniority })
          : null,
        kayakSprint: entry.lyg_kayak_sprint_age_category
          ? new KayakSprintEntry({
              ageCategory: entry.lyg_kayak_sprint_age_category,
              reserve: Boolean(entry.lyg_kayak_sprint_reserve),
            })
          : null,
        pgAthletics:
          entry.lyg_pg_athletics_disability_category && entry.lyg_pg_athletics_event_1 && entry.lyg_pg_athletics_event_2
            ? new PgAthleticsEntry({
                disabilityCategory: entry.lyg_pg_athletics_disability_category,
                event1: entry.lyg_pg_athletics_event_1,
                event2: entry.lyg_pg_athletics_event_2,
                fourTimesOneHundredMetersRelay: Boolean(entry.lyg_pg_athletics_four_times_one_hundred_meters_relay),
              })
            : null,
        pgSwimming:
          entry.lyg_pg_swimming_four_times_twenty_five_meters_backstroke ||
          entry.lyg_pg_swimming_four_times_twenty_five_meters_freestyle
            ? new PgSwimmingEntry({
                fourTimesTwentyFiveMetersBackstroke: Boolean(
                  entry.lyg_pg_swimming_four_times_twenty_five_meters_backstroke,
                ),
                fourTimesTwentyFiveMetersFreestyle: Boolean(
                  entry.lyg_pg_swimming_four_times_twenty_five_meters_backstroke,
                ),
                reserve: Boolean(entry.lyg_pg_swimming_reserve),
                swimmingEvent: entry.lyg_pg_swimming_event ?? '',
              })
            : null,
        swimming:
          entry.lyg_swimming_competition_event &&
          entry.lyg_swimming_entry_time &&
          entry.lyg_swimming_entry_time_course &&
          entry.lyg_swimming_membership_number &&
          entry.lyg_swimming_para_swimming_classification
            ? new SwimmingEntry({
                competitionEvent: entry.lyg_swimming_competition_event,
                entryTime: entry.lyg_swimming_entry_time,
                entryTimeCourse: entry.lyg_swimming_entry_time_course,
                fourTimesFiftyMetersFreestyle: Boolean(entry.swimming_four_times_fifty_meters_freestyle),
                fourTimesFiftyMetersMedley: Boolean(entry.swimming_four_times_fifty_meters_medley),
                membershipNumber: entry.lyg_swimming_membership_number,
                paraSwimmingClassification: entry.lyg_swimming_para_swimming_classification,
                reserve: Boolean(entry.lyg_swimming_reserve),
                swimmingEvent: entry.lyg_swimming_event ?? '',
              })
            : null,
        tennis:
          entry.lyg_tennis_itf_world_tennis_number && entry.lyg_tennis_membership_number && entry.lyg_tennis_seniority
            ? new TennisEntry({
                itfWorldTennisNumber: entry.lyg_tennis_itf_world_tennis_number,
                membershipNumber: entry.lyg_tennis_membership_number,
                seniority: entry.lyg_tennis_seniority,
              })
            : null,
        trampoline:
          entry.lyg_trampoline_membership_number && entry.lyg_trampoline_tier
            ? new TrampolineEntry({
                membershipNumber: entry.lyg_trampoline_membership_number,
                reserve: Boolean(entry.lyg_trampoline_reserve),
                tier: entry.lyg_trampoline_tier,
              })
            : null,
        swimmingRecreational:
          entry.lyg_swimming_recreational_event && entry.lyg_swimming_recreational_event_number
            ? new SwimmingRecreationalEntry({
                event: entry.lyg_swimming_recreational_event,
                eventNumber: entry.lyg_swimming_recreational_event_number,
                level: entry.lyg_swimming_recreational_level,
                ageGroup: entry.lyg_swimming_recreational_age_group,
                eventSex: entry.lyg_swimming_recreational_event_sex,
              })
            : null,
        swimmingCompetitive:
          entry.lyg_swimming_competitive_event && entry.lyg_swimming_competitive_event_number
            ? {
                event: entry.lyg_swimming_competitive_event,
                eventNumber: entry.lyg_swimming_competitive_event_number,
                level: entry.lyg_swimming_competitive_level,
                ageGroup: entry.lyg_swimming_competitive_age_group,
                eventSex: entry.lyg_swimming_competitive_event_sex,
                entryTime: entry.lyg_swimming_competitive_entry_time,
                entryTimeCourse: entry.lyg_swimming_competitive_entry_time_course,
                registrationNumber: entry.lyg_swimming_competitive_registration_number,
              }
            : null,
        crossCountry: entry.lyg_cross_country_event_age_category
          ? new CrossCountryEntry({ ageCategory: entry.lyg_cross_country_event_age_category })
          : null,
        roadCycling: entry.lyg_road_cycling_membership_number
          ? new RoadCyclingEntry({
              licenseNumber: entry.lyg_road_cycling_membership_number,
              timeTrialCompetitor: Boolean(entry.lyg_road_cycling_time_trial_competitor),
              timeTrialAgeCategory: entry.lyg_road_cycling_time_trial_category ?? '',
              roadRaceCompetitor: Boolean(entry.lyg_road_cycling_road_race_competitor),
              reserve: Boolean(entry.lyg_road_cycling_reserve),
            })
          : null,
      }),
  );
}

async function getEntries(filters: QueryFilters, token: AccessToken): Promise<PaginatedApiResponse<EntryApiResponse>> {
  const isFilterApplied = filters.search;
  // ||
  // filters.categoryType ||
  // filters.sportId ||
  // filters.startDate ||
  // filters.endDate ||
  // filters.boroughId ||
  // filters.competitionId ||
  // filters.ageGroup;

  const params: string[] = [`page=${isFilterApplied ? 1 : filters?.page || 1}`];
  if (filters.search) params.push(`search=${filters.search}`);
  if (filters.itemsPerPage) params.push(`itemsPerPage=${filters.itemsPerPage}`);
  if (filters.categoryType) params.push(`gender=${filters.categoryType}`);
  if (filters.sportId) params.push(`sport_id=${filters.sportId}`);
  if (filters.startDate) params.push(`lyg_event_start_date=${dateToISONoTimezone(filters.startDate)}`);
  if (filters.endDate) params.push(`lyg_event_end_date=${dateToISONoTimezone(filters.endDate)}`);
  if (filters.eventType) params.push(`lyg_event_type=${filters.eventType}`);
  if (filters.boroughId) params.push(`lyg_borough_id=${filters.boroughId}`);
  if (filters.competitionId) params.push(`lyg_competition_id=${filters.competitionId}`);
  if (filters.ageGroup) params.push(`age_group=${filters.ageGroup}`);
  if (filters.noPagination) params.push(`no_pagination=${filters.noPagination}`);

  const searchQuery = params.join('&');

  return apiClient(`/v1/lyg/entries?${searchQuery}`, { token });
}

export interface QueryResult {
  count: number;
  entries: LYGEntry[];
}

export function useLYGEntriesQuery(filters: QueryFilters) {
  const { token } = useAuthContext();
  return useQuery<QueryResult, ApiError>(LYG_ENTRIES_KEYS.filtered(filters), async (): Promise<QueryResult> => {
    const { result } = await getEntries(filters, token);
    return {
      count: result.total,
      entries: mapEntriesResponseToEntries(result.data),
    };
  });
}

export function useLYGAllEntriesQuery(filters: QueryFilters) {
  const { token } = useAuthContext();
  return useQuery<LYGEntry[], ApiError>(LYG_ENTRIES_KEYS.filtered(filters), async (): Promise<LYGEntry[]> => {
    const { result } = await getEntries(filters, token);
    return mapEntriesResponseToEntries(result as unknown as EntryApiResponse[]);
  });
}
