import { apiClient } from 'src/shared/utils/apiClient';
import { dateToISONoTimezone } from 'src/shared/utils/dates';

interface CreateVideoRequest {
  date: Date;
  description: string;
  eventId?: number;
  name: string;
  opponent: string;
  teamId: number;
  type: string;
  url: string;
  gameId?: number;
}

export async function createVideo(video: CreateVideoRequest, token: AccessToken): Promise<void> {
  await apiClient('/videos/create', {
    body: JSON.stringify({
      category: video.type,
      event_id: video.eventId || null,
      team_id: video.teamId,
      video_date: dateToISONoTimezone(video.date, true),
      video_description: video.description,
      video_name: video.name,
      video_opponent_name: video.opponent,
      video_url: video.url,
      game_id: video.gameId || null,
    }),
    method: 'POST',
    token,
  });
}
