import React from 'react';
import { cx } from 'src/shared/utils/common';
import { observer } from 'mobx-react-lite';
import { DataTableDataItem, DATA_TABLE_SORT_ORDER } from 'src/shared/ui/data/types';
import { SortIcon } from 'src/shared/ui/assets/SortIcon';
import { DataTableColumnModel } from 'src/shared/ui/data/DataTableColumnModel';
import { useI18nContext } from 'src/context/i18n';
import css from './DataTable.module.scss';

interface DataTableColumnHeaderProps<T extends DataTableDataItem> {
  model: DataTableColumnModel<T>;
}

export const DataTableColumnHeader = observer(
  <T extends DataTableDataItem>({ model }: DataTableColumnHeaderProps<T>): React.ReactElement | null => {
    const {
      overflows,
      props: { className, headerOnlyClassName, hideFromTable, sortable, table, title, width },
      sortOrder,
      onHeaderClick,
    } = model;
    const i18n = useI18nContext();

    if (overflows || (hideFromTable && hideFromTable(table))) {
      return null;
    }

    if (sortable) {
      const sortedAscending = sortOrder === DATA_TABLE_SORT_ORDER.ascending;
      const ariaLabel = sortedAscending
        ? i18n.t('shared.fields.labels.sortDescending', { column: title as string })
        : i18n.t('shared.fields.labels.sortAscending', { column: title as string });

      return (
        <button
          aria-label={ariaLabel}
          className={cx(
            'p-0 bg-transparent d-flex align-items-center text-left',
            css.tableCell,
            className,
            headerOnlyClassName,
          )}
          style={{ width: width }}
          title={ariaLabel}
          type='button'
          onClick={onHeaderClick}
        >
          <div>{title}</div>
          <SortIcon
            className={cx(
              'd-block ms-2 flex-shrink-0',
              sortedAscending && css.sortAscending,
              sortOrder === DATA_TABLE_SORT_ORDER.descending && css.sortDescending,
            )}
          />
        </button>
      );
    }

    return (
      <div
        className={cx('ts-text-overflow text-left', css.tableCell, className, headerOnlyClassName)}
        style={{ width: width }}
      >
        {title}
      </div>
    );
  },
);
