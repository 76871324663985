export const PERMISSIONS_KEY = 'TS_PERMISSIONS' as const;

// TODO: Convert this to an object and use it across the app
export const localStorageKeys: readonly string[] = [
  'EventsCount',
  'InvitedPlayersCount',
  'PlayersCount',
  'TeamsCount',
  'VideosCount',
  'PastEvents',
  'UpcomingEvents',
  'HighlightsCount',
  'SELECTED_TEAM',
  'SAFARI_PUSH_NOTIFICATIONS',
  'TS_JWT',
  'VideoAnalysisTeamOrder',
  PERMISSIONS_KEY,
] as const;
