import { useQuery, UseQueryResult } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { PLAYER_QUERY_KEYS } from 'src/entities/players/queries/queryKeys';
import { getInvitedPlayers, GET_INVITED_PLAYERS_SORT_BY } from 'src/pages/players/list/apis/getInvitedPlayers';
import { InvitedPlayer, INVITED_PLAYER_STATUS } from 'src/pages/players/list/domain';
import { INVITED_PLAYERS_TABLE_SORT_KEY } from 'src/pages/teams/team/models/TeamPendingPlayersViewModel';

interface UseInvitedPlayersQueryInput {
  filters: {
    email?: string;
    name?: string;
    teamId?: number;
  };
  limit?: number;
  page?: number;
  sortAscending?: boolean;
  sortBy?: INVITED_PLAYERS_TABLE_SORT_KEY;
}

interface UsePlayersQueryData {
  invitedPlayers: InvitedPlayer[];
  totalCount: number;
}

const sortBy: Record<INVITED_PLAYERS_TABLE_SORT_KEY, GET_INVITED_PLAYERS_SORT_BY | undefined> = {
  created: undefined,
  email: GET_INVITED_PLAYERS_SORT_BY.email,
  name: GET_INVITED_PLAYERS_SORT_BY.name,
};

export function useInvitedPlayersQuery(input: UseInvitedPlayersQueryInput): UseQueryResult<UsePlayersQueryData, Error> {
  const { token } = useAuthContext();

  return useQuery<UsePlayersQueryData, Error>(
    PLAYER_QUERY_KEYS.invited.filtered(input),
    async () => {
      const response = await getInvitedPlayers({
        ...input,
        sortBy: sortBy[input.sortBy || 'created'],
        token,
      });
      return {
        invitedPlayers: response.data.map(invitedPlayer => ({
          created: new Date(invitedPlayer.created_at),
          email: invitedPlayer.invite_email,
          firstName: invitedPlayer.profile_first_name,
          lastName: invitedPlayer.profile_last_name,
          guardianEmail: invitedPlayer.invite_guardian_email || undefined,
          id: invitedPlayer.user_id,
          isMinor: invitedPlayer.invite_guardian_email ? true : false,
          teamName: invitedPlayer.team_name || undefined,
          profileAvatar: invitedPlayer.profile_avatar,
          status: (invitedPlayer.invite_status as INVITED_PLAYER_STATUS) || INVITED_PLAYER_STATUS.pending,
          inviteId: invitedPlayer.invite_id,
        })),
        totalCount: response.total,
      };
    },
    {
      staleTime: 1000 * 60 * 30,
    },
  );
}
