import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuthContext } from 'src/context/authContext';
import { LYGEntry } from 'src/lyg/entities/entries/entry';
import { LYGSchoolGameEntry } from 'src/lyg/entities/entries/schoolGameEntry';
import { mapEntriesResponseToEntries as mapOpenGamesEntries } from 'src/lyg/pages/entries/home/getEntriesQuery';
import { LYG_ENTRIES_KEYS, LYG_SCHOOL_ENTRIES_KEYS } from 'src/lyg/pages/entries/queryKeys';
import { ParticipantFormData } from 'src/lyg/pages/entries/shared/LYGEntryGeneralInfoModel';
import { LYGEntryOpenGamesModel } from 'src/lyg/pages/entries/shared/LYGOpenGamesModel';
import { DemographicFormData } from 'src/lyg/pages/entries/shared/LYGSchoolEntryDemographicDataModel';
import { LYGEntrySchoolModel } from 'src/lyg/pages/entries/shared/LYGSchoolGamesEntryModel';
import { apiClient } from 'src/lyg/utils/apiClient';
import { ApiError } from 'src/lyg/utils/apiError';
import { dateToISONoTimezone } from 'src/shared/utils/dates';

interface GetOpenGameEntryResponse {
  result: {
    lyg_entry_id: number;
    lyg_name: string;
    lyg_gender: string;
    lyg_email: string | null;
    lyg_parent_name: string | null;
    lyg_parent_email: string | null;
    lyg_borough_id: number;
    lyg_borough_name: string;
    lyg_dob: string;
    lyg_ethnicity: string;
    lyg_disability: string;
    lyg_home_postcode: string;
    lyg_school: string;
    lyg_emergency_contact_name: string;
    lyg_emergency_contact_number: string;
    lyg_imagery_consent: 1 | 0;
    lyg_competition_name: string | null;
    lyg_aquathlon_reserve: 1 | 0 | null;
    lyg_athletics_track_event: string | null;
    lyg_athletics_field_event: string | null;
    lyg_athletics_four_times_one_hundred_meters_relay: 1 | 0 | null;
    lyg_bmx_license_number: string | null;
    lyg_bmx_grade: string | null;
    lyg_bmx_reserve: 1 | 0 | null;
    lyg_indoor_rowing_reserve: 1 | 0 | null;
    lyg_indoor_rowing_year_group: string | null;
    lyg_judo_license_number: string | null;
    lyg_judo_weight: number | null;
    lyg_judo_dan_grade: string | null;
    lyg_judo_belt_colour: string | null;
    lyg_judo_section: string | null;
    lyg_judo_boys_section_a_weight: string | null;
    lyg_judo_boys_section_b_weight: string | null;
    lyg_judo_boys_section_c_weight: string | null;
    lyg_judo_girls_section_a_weight: string | null;
    lyg_judo_girls_section_b_weight: string | null;
    lyg_judo_girls_section_c_weight: string | null;
    lyg_kayak_slalom_seniority: string | null;
    lyg_kayak_sprint_age_category: string | null;
    lyg_kayak_sprint_reserve: 1 | 0 | null;
    lyg_pg_athletics_disability_category: string | null;
    lyg_pg_athletics_event_1: string | null;
    lyg_pg_athletics_event_2: string | null;
    lyg_pg_athletics_four_times_one_hundred_meters_relay: 1 | 0 | null;
    lyg_pg_swimming_reserve: 1 | 0 | null;
    lyg_pg_swimming_four_times_twenty_five_meters_freestyle: 1 | 0 | null;
    lyg_pg_swimming_four_times_twenty_five_meters_backstroke: 1 | 0 | null;
    lyg_pg_swimming_event: string | null;
    lyg_swimming_reserve: 1 | 0 | null;
    lyg_swimming_membership_number: string | null;
    lyg_swimming_entry_time: number | null;
    lyg_swimming_entry_time_course: string | null;
    lyg_swimming_competition_event: string | null;
    lyg_swimming_para_swimming_classification: string | null;
    lyg_swimming_event: string | null;
    swimming_four_times_fifty_meters_medley: 1 | 0 | null;
    swimming_four_times_fifty_meters_freestyle: 1 | 0 | null;
    lyg_tennis_membership_number: string | null;
    lyg_tennis_itf_world_tennis_number: string | null;
    lyg_tennis_seniority: string | null;
    lyg_trampoline_reserve: 1 | 0 | null;
    lyg_trampoline_tier: string | null;
    lyg_trampoline_membership_number: string | null;
    lyg_road_cycling_membership_number: string | null;
    lyg_road_cycling_time_trial_competitor: 1 | 0 | null;
    lyg_road_cycling_time_trial_category: string | null;
    lyg_road_cycling_reserve: 1 | 0 | null;
    lyg_road_cycling_road_race_competitor: 1 | 0 | null;
    lyg_cross_country_event_age_category: string | null;
    lyg_swimming_recreational_event_number: number | null;
    lyg_swimming_recreational_event: string | null;
    lyg_swimming_recreational_age_group: string | null;
    lyg_swimming_recreational_event_sex: string | null;
    lyg_swimming_recreational_level: string | null;
    lyg_swimming_competitive_event_number: number | null;
    lyg_swimming_competitive_event: string | null;
    lyg_swimming_competitive_age_group: string | null;
    lyg_swimming_competitive_event_sex: string | null;
    lyg_swimming_competitive_level: string | null;
    lyg_swimming_competitive_entry_time: string | null;
    lyg_swimming_competitive_entry_time_course: string | null;
    lyg_swimming_competitive_registration_number: string | null;
  };
}

interface GetSchoolGameEntryResponse {
  result: {
    lyg_school_game_entry_id: number;
    lyg_sport_id: string;
    lyg_school_id: number;
    lyg_borough_id: number;
    lyg_competition_name: string;
    lyg_number_of_teams: number;
    lyg_member_id: number;
    lyg_school_name: string;
    lyg_borough_name: string;
    profile_first_name: string;
    profile_last_name: string;
    profile_telephone1: string;
    email: string;
    lyg_has_demographic_data: 1 | 0;
    participant_data: {
      lyg_how_select_squad: string | null;
      lyg_number_of_boys: number;
      lyg_number_of_girls: number;
      lyg_number_of_non_binary: number;
      lyg_number_of_other: number;
      lyg_number_of_no_response: number;
    };
    demographic_data: {
      lyg_demographic_data_id: number;
      lyg_asian_british_boys: number;
      lyg_asian_british_girls: number;
      lyg_asian_british_non_binary_other: number;
      lyg_black_british_boys: number;
      lyg_black_british_girls: number;
      lyg_black_british_non_binary_other: number;
      lyg_mixed_race_boys: number;
      lyg_mixed_race_girls: number;
      lyg_mixed_race_non_binary_other: number;
      lyg_white_british_boys: number;
      lyg_white_british_girls: number;
      lyg_white_british_non_binary_other: number;
      lyg_white_other_boys: number;
      lyg_white_other_girls: number;
      lyg_white_other_non_binary_other: number;
      lyg_other_ethnicity_boys: number;
      lyg_other_ethnicity_girls: number;
      lyg_other_ethnicity_non_binary_other: number;
      lyg_prefer_not_to_say_ethnicity_boys: number;
      lyg_prefer_not_to_say_ethnicity_girls: number;
      lyg_prefer_not_to_say_ethnicity_non_binary_other: number;
      lyg_g1_disability_boys: number;
      lyg_g1_disability_girls: number;
      lyg_g1_disability_non_binary_other: number;
      lyg_g2_disability_boys: number;
      lyg_g2_disability_girls: number;
      lyg_g2_disability_non_binary_other: number;
      lyg_g3a_disability_boys: number;
      lyg_g3a_disability_girls: number;
      lyg_g3a_disability_non_binary_other: number;
      lyg_g3b_disability_boys: number;
      lyg_g3b_disability_girls: number;
      lyg_g3b_disability_non_binary_other: number;
      lyg_g4_disability_boys: number;
      lyg_g4_disability_girls: number;
      lyg_g4_disability_non_binary_other: number;
      lyg_g5_disability_boys: number;
      lyg_g5_disability_girls: number;
      lyg_g5_disability_non_binary_other: number;
      lyg_behavioural_disability_boys: number;
      lyg_behavioural_disability_girls: number;
      lyg_behavioural_disability_non_binary_other: number;
      lyg_mental_health_disability_boys: number;
      lyg_mental_health_disability_girls: number;
      lyg_mental_health_disability_non_binary_other: number;
      lyg_other_disability_boys: number;
      lyg_other_disability_girls: number;
      lyg_other_disability_non_binary_other: number;
      lyg_none_disability_boys: number;
      lyg_none_disability_girls: number;
      lyg_none_disability_non_binary_other: number;
      lyg_prefer_not_to_say_disability_boys: number;
      lyg_prefer_not_to_say_disability_girls: number;
      lyg_prefer_not_to_say_disability_non_binary_other: number;
    } | null;
  };
}

function mapSchoolGamesEntry(entry: GetSchoolGameEntryResponse['result']): LYGSchoolGameEntry {
  const participantData =
    entry.participant_data != null
      ? {
          howSelectSquad: entry.participant_data.lyg_how_select_squad || '',
          totalBoysParticipants: entry.participant_data.lyg_number_of_boys,
          totalGirlsParticipants: entry.participant_data.lyg_number_of_girls,
          totalNonBinaryParticipants: entry.participant_data.lyg_number_of_non_binary,
          totalOtherParticipants: entry.participant_data.lyg_number_of_other,
          totalNoResponseParticipants: entry.participant_data.lyg_number_of_no_response,
        }
      : undefined;
  const demographicData =
    entry.demographic_data !== null
      ? {
          asianBoys: entry.demographic_data.lyg_asian_british_boys,
          asianGirls: entry.demographic_data.lyg_asian_british_girls,
          asianNonBinaryOther: entry.demographic_data.lyg_asian_british_non_binary_other,
          blackBritishBoys: entry.demographic_data.lyg_black_british_boys,
          blackBritishGirls: entry.demographic_data.lyg_black_british_girls,
          blackBritishNonBinaryOther: entry.demographic_data.lyg_black_british_non_binary_other,
          mixedRaceBoys: entry.demographic_data.lyg_mixed_race_boys,
          mixedRaceGirls: entry.demographic_data.lyg_mixed_race_girls,
          mixedRaceNonBinaryOther: entry.demographic_data.lyg_mixed_race_non_binary_other,
          whiteBritishBoys: entry.demographic_data.lyg_white_british_boys,
          whiteBritishGirls: entry.demographic_data.lyg_white_british_girls,
          whiteBritishNonBinaryOther: entry.demographic_data.lyg_white_british_non_binary_other,
          whiteOtherBoys: entry.demographic_data.lyg_white_other_boys,
          whiteOtherGirls: entry.demographic_data.lyg_white_other_girls,
          whiteOtherNonBinaryOther: entry.demographic_data.lyg_white_other_non_binary_other,
          otherEthnicityBoys: entry.demographic_data.lyg_other_ethnicity_boys,
          otherEthnicityGirls: entry.demographic_data.lyg_other_ethnicity_girls,
          otherEthnicityNonBinaryOther: entry.demographic_data.lyg_other_ethnicity_non_binary_other,
          preferNotToSayEthnicityBoys: entry.demographic_data.lyg_prefer_not_to_say_ethnicity_boys,
          preferNotToSayEthnicityGirls: entry.demographic_data.lyg_prefer_not_to_say_ethnicity_girls,
          preferNotToSayEthnicityNonBinaryOther:
            entry.demographic_data.lyg_prefer_not_to_say_disability_non_binary_other,
          g1DisabilityBoys: entry.demographic_data.lyg_g1_disability_boys,
          g1DisabilityGirls: entry.demographic_data.lyg_g1_disability_girls,
          g1DisabilityNonBinaryOther: entry.demographic_data.lyg_g1_disability_non_binary_other,
          g2DisabilityBoys: entry.demographic_data.lyg_g2_disability_boys,
          g2DisabilityGirls: entry.demographic_data.lyg_g2_disability_girls,
          g2DisabilityNonBinaryOther: entry.demographic_data.lyg_g2_disability_non_binary_other,
          g3aDisabilityBoys: entry.demographic_data.lyg_g3a_disability_boys,
          g3aDisabilityGirls: entry.demographic_data.lyg_g3a_disability_girls,
          g3aDisabilityNonBinaryOther: entry.demographic_data.lyg_g3a_disability_non_binary_other,
          g3bDisabilityBoys: entry.demographic_data.lyg_g3b_disability_boys,
          g3bDisabilityGirls: entry.demographic_data.lyg_g3a_disability_girls,
          g3bDisabilityNonBinaryOther: entry.demographic_data.lyg_g3b_disability_non_binary_other,
          g4DisabilityBoys: entry.demographic_data.lyg_g4_disability_boys,
          g4DisabilityGirls: entry.demographic_data.lyg_g4_disability_girls,
          g4DisabilityNonBinaryOther: entry.demographic_data.lyg_g4_disability_non_binary_other,
          g5DisabilityBoys: entry.demographic_data.lyg_g5_disability_boys,
          g5DisabilityGirls: entry.demographic_data.lyg_g5_disability_girls,
          g5DisabilityNonBinaryOther: entry.demographic_data.lyg_g5_disability_non_binary_other,
          behaviouralDisabilityBoys: entry.demographic_data.lyg_behavioural_disability_boys,
          behaviouralDisabilityGirls: entry.demographic_data.lyg_behavioural_disability_girls,
          behaviouralDisabilityNonBinaryOther: entry.demographic_data.lyg_behavioural_disability_non_binary_other,
          mentalHealthDisabilityBoys: entry.demographic_data.lyg_mental_health_disability_boys,
          mentalHealthDisabilityGirls: entry.demographic_data.lyg_mental_health_disability_girls,
          mentalHealthDisabilityNonBinaryOther: entry.demographic_data.lyg_mental_health_disability_non_binary_other,
          noneDisabilityBoys: entry.demographic_data.lyg_none_disability_boys,
          noneDisabilityGirls: entry.demographic_data.lyg_none_disability_girls,
          noneDisabilityNonBinaryOther: entry.demographic_data.lyg_none_disability_non_binary_other,
          otherDisabilityBoys: entry.demographic_data.lyg_other_disability_boys,
          otherDisabilityGirls: entry.demographic_data.lyg_other_ethnicity_girls,
          otherDisabilityNonBinaryOther: entry.demographic_data.lyg_other_disability_non_binary_other,
          preferNotToSayDisabilityBoys: entry.demographic_data.lyg_prefer_not_to_say_disability_boys,
          preferNotToSayDisabilityGirls: entry.demographic_data.lyg_prefer_not_to_say_disability_girls,
          preferNotToSayDisabilityNonBinaryOther:
            entry.demographic_data.lyg_prefer_not_to_say_disability_non_binary_other,
        }
      : undefined;
  return {
    id: entry.lyg_school_game_entry_id,
    competitionName: entry.lyg_competition_name,
    schoolId: entry.lyg_school_id,
    boroughId: entry.lyg_borough_id,
    boroughName: entry.lyg_borough_name,
    numberOfTeams: entry.lyg_number_of_teams,
    schoolName: entry.lyg_school_name,
    teamManagerId: entry.lyg_member_id,
    teamManagerName: entry.profile_first_name,
    teamManagerLastName: entry.profile_last_name,
    teamManagerEmail: entry.email,
    teamManagerContactNumber: entry.profile_telephone1,
    hasDemographicData: Boolean(entry.lyg_has_demographic_data),
    participantData: participantData,
    demographicData: demographicData,
  };
}

async function getOpenGameEntry(entryId: number, token: AccessToken): Promise<GetOpenGameEntryResponse> {
  return apiClient(`/v1/lyg/entries/${entryId}`, { token });
}

async function getSchoolGameEntry(entryId: number, token: AccessToken): Promise<GetSchoolGameEntryResponse> {
  return apiClient(`/v1/lyg/entries/schoolgames/${entryId}`, { token });
}

export function useLYGSchoolGameEntry(entryId: number): UseQueryResult<LYGSchoolGameEntry, ApiError> {
  const { token } = useAuthContext();

  return useQuery<LYGSchoolGameEntry, ApiError>(LYG_SCHOOL_ENTRIES_KEYS.entry(entryId), async () => {
    const { result } = await getSchoolGameEntry(entryId, token);
    return mapSchoolGamesEntry(result);
  });
}

export function useLYGOpenGameEntry(entryId: number): UseQueryResult<LYGEntry, ApiError> {
  const { token } = useAuthContext();

  return useQuery<LYGEntry, ApiError>(LYG_ENTRIES_KEYS.entry(entryId), async () => {
    const { result } = await getOpenGameEntry(entryId, token);
    const entry = mapOpenGamesEntries([result])[0];
    return entry;
  });
}

interface updateEntryRequest {
  boroughId: string;
  schoolId: string;
  numberOfTeams: string;
  teamManagerId: string;
  teamManagerName: string;
  teamManagerLastName: string;
  teamManagerEmail: string;
  teamManagerContactNumber: string;
  entryId: number;
  participantData: ParticipantFormData;
  demographicData: DemographicFormData;
}

interface UploadResponse {
  lyg_school_game_entry_id: number;
}

async function uploadEntries(request: updateEntryRequest, token: AccessToken): Promise<UploadResponse> {
  const data = new FormData();
  if (request.boroughId) data.append('lyg_borough_id', request.boroughId);
  if (request.schoolId) data.append('lyg_school_id', request.schoolId);
  if (request.numberOfTeams) data.append('lyg_number_of_teams', request.numberOfTeams);
  if (request.teamManagerId) data.append('lyg_member_id', request.teamManagerId);
  if (request.teamManagerName) data.append('lyg_team_manager_name', request.teamManagerName);
  if (request.teamManagerLastName) data.append('lyg_team_manager_last_name', request.teamManagerLastName);
  if (request.teamManagerEmail) data.append('lyg_team_manager_email', request.teamManagerEmail);
  if (request.teamManagerContactNumber)
    data.append('lyg_team_manager_contact_number', request.teamManagerContactNumber);
  //participant data
  if (request.participantData.totalBoysParticipants)
    data.append('lyg_boys_participants', request.participantData.totalBoysParticipants);
  if (request.participantData.totalGirlsParticipants)
    data.append('lyg_girls_participants', request.participantData.totalGirlsParticipants);
  if (request.participantData.totalNonBinaryParticipants)
    data.append('lyg_non_binary_participants', request.participantData.totalNonBinaryParticipants);
  if (request.participantData.totalOtherParticipants)
    data.append('lyg_other_participants', request.participantData.totalOtherParticipants);
  if (request.participantData.totalNoResponseParticipants)
    data.append('lyg_no_response_participants', request.participantData.totalNoResponseParticipants);
  if (request.participantData.howSelectSquad)
    data.append('lyg_how_select_squad', request.participantData.howSelectSquad);
  //demographic data

  data.append('lyg_asian_british_boys', request.demographicData.asianBoys);
  data.append('lyg_asian_british_girls', request.demographicData.asianGirls);
  data.append('lyg_asian_british_non_binary_other', request.demographicData.asianNonBinaryOther);

  data.append('lyg_black_british_boys', request.demographicData.blackBritishBoys);
  data.append('lyg_black_british_girls', request.demographicData.blackBritishGirls);
  data.append('lyg_black_british_non_binary_other', request.demographicData.blackBritishNonBinaryOther);

  data.append('lyg_mixed_race_boys', request.demographicData.mixedRaceBoys);
  data.append('lyg_mixed_race_girls', request.demographicData.mixedRaceGirls);
  data.append('lyg_mixed_race_non_binary_other', request.demographicData.mixedRaceNonBinaryOther);

  data.append('lyg_white_british_boys', request.demographicData.whiteBritishBoys);
  data.append('lyg_white_british_girls', request.demographicData.whiteBritishGirls);
  data.append('lyg_white_british_non_binary_other', request.demographicData.whiteBritishNonBinaryOther);

  data.append('lyg_white_other_boys', request.demographicData.whiteOtherBoys);
  data.append('lyg_white_other_girls', request.demographicData.whiteOtherGirls);
  data.append('lyg_white_other_non_binary_other', request.demographicData.whiteOtherNonBinaryOther);

  data.append('lyg_other_ethnicity_boys', request.demographicData.otherEthnicityBoys);
  data.append('lyg_other_ethnicity_girls', request.demographicData.otherEthnicityGirls);
  data.append('lyg_other_ethnicity_non_binary_other', request.demographicData.otherEthnicityNonBinaryOther);

  data.append('lyg_prefer_not_to_say_ethnicity_boys', request.demographicData.preferNotToSayEthnicityBoys);
  data.append('lyg_prefer_not_to_say_ethnicity_girls', request.demographicData.preferNotToSayEthnicityGirls);
  data.append(
    'lyg_prefer_not_to_say_ethnicity_non_binary_other',
    request.demographicData.preferNotToSayEthnicityNonBinaryOther,
  );

  data.append('lyg_g1_disability_boys', request.demographicData.g1DisabilityBoys);
  data.append('lyg_g1_disability_girls', request.demographicData.g1DisabilityGirls);
  data.append('lyg_g1_disability_non_binary_other', request.demographicData.g1DisabilityNonBinaryOther);

  data.append('lyg_g2_disability_boys', request.demographicData.g2DisabilityBoys);
  data.append('lyg_g2_disability_girls', request.demographicData.g2DisabilityGirls);
  data.append('lyg_g2_disability_non_binary_other', request.demographicData.g2DisabilityNonBinaryOther);

  data.append('lyg_g3a_disability_boys', request.demographicData.g3aDisabilityBoys);
  data.append('lyg_g3a_disability_girls', request.demographicData.g3aDisabilityGirls);
  data.append('lyg_g3a_disability_non_binary_other', request.demographicData.g3aDisabilityNonBinaryOther);

  data.append('lyg_g3b_disability_boys', request.demographicData.g3bDisabilityBoys);
  data.append('lyg_g3b_disability_girls', request.demographicData.g3bDisabilityGirls);
  data.append('lyg_g3b_disability_non_binary_other', request.demographicData.g3bDisabilityNonBinaryOther);

  data.append('lyg_g4_disability_boys', request.demographicData.g4DisabilityBoys);
  data.append('lyg_g4_disability_girls', request.demographicData.g4DisabilityGirls);
  data.append('lyg_g4_disability_non_binary_other', request.demographicData.g4DisabilityNonBinaryOther);

  data.append('lyg_g5_disability_boys', request.demographicData.g5DisabilityBoys);
  data.append('lyg_g5_disability_girls', request.demographicData.g5DisabilityGirls);
  data.append('lyg_g5_disability_non_binary_other', request.demographicData.g5DisabilityNonBinaryOther);

  data.append('lyg_behavioural_disability_boys', request.demographicData.behaviouralDisabilityBoys);
  data.append('lyg_behavioural_disability_girls', request.demographicData.behaviouralDisabilityGirls);
  data.append(
    'lyg_behavioural_disability_non_binary_other',
    request.demographicData.behaviouralDisabilityNonBinaryOther,
  );
  data.append('lyg_mental_health_disability_boys', request.demographicData.mentalHealthDisabilityBoys);
  data.append('lyg_mental_health_disability_girls', request.demographicData.mentalHealthDisabilityGirls);
  data.append(
    'lyg_mental_health_disability_non_binary_other',
    request.demographicData.mentalHealthDisabilityNonBinaryOther,
  );
  data.append('lyg_other_disability_boys', request.demographicData.otherDisabilityBoys);
  data.append('lyg_other_disability_girls', request.demographicData.otherDisabilityGirls);
  data.append('lyg_other_disability_non_binary_other', request.demographicData.otherDisabilityNonBinaryOther);
  data.append('lyg_none_disability_boys', request.demographicData.noneDisabilityBoys);
  data.append('lyg_none_disability_girls', request.demographicData.noneDisabilityGirls);
  data.append('lyg_none_disability_non_binary_other', request.demographicData.noneDisabilityNonBinaryOther);
  data.append('lyg_prefer_not_to_say_disability_boys', request.demographicData.preferNotToSayDisabilityBoys);
  data.append('lyg_prefer_not_to_say_disability_girls', request.demographicData.preferNotToSayDisabilityGirls);
  data.append(
    'lyg_prefer_not_to_say_disability_non_binary_other',
    request.demographicData.preferNotToSayDisabilityNonBinaryOther,
  );

  return apiClient(`/v1/lyg/entries/schoolgames/${request.entryId}`, {
    method: 'POST',
    token,
    body: data,
  });
}

export function useUploadLYGSchoolGamesEntriesMutation(formModel: LYGEntrySchoolModel, entryId: number) {
  const queryClient = useQueryClient();
  const { token } = useAuthContext();

  const mutation = useMutation<UploadResponse, Error, void>(
    async () => await uploadEntries({ ...formModel.data, entryId }, token),
    {
      onSuccess() {
        queryClient.invalidateQueries(LYG_SCHOOL_ENTRIES_KEYS.base);
      },
    },
  );

  return {
    isSuccess: mutation.isSuccess,
    error: mutation.isError,
    isLoading: mutation.isLoading,
    execute: mutation.mutate,
  };
}

interface uploadOpenGamesEntriesRequest {
  name: string;
  gender: string;
  email: string;
  parentName: string;
  parentEmail: string;
  dob: Date;
  borough: string;
  school: string;
  ethnicity: string;
  disability: string;
  homePostCode: string;
  emergencyContactNumber: string;
  emergencyContactName: string;
  imageryConsent: boolean;
  aquathlonReserve: boolean;
  athleticsTrackEvent: string;
  athleticsFieldEvent: string;
  athleticsFourTimesOneHundredMetersRelay: boolean;
  bmxLicenseNumber: string;
  bmxGrade: string;
  bmxReserve: boolean;
  indoorRowingReserve: boolean;
  indoorRowingCategoryAgeGroup: string;
  judoLicenseNumber: string;
  judoDanGrade: string;
  judoSection: string;
  judoWeightBoysSectionA: string;
  judoWeightBoysSectionB: string;
  judoWeightBoysSectionC: string;
  judoWeightGirlsSectionA: string;
  judoWeightGirlsSectionB: string;
  judoWeightGirlsSectionC: string;
  kayakSlalomSeniority: string;
  kayakSprintAgeCategory: string;
  kayakSprintReserve: boolean;
  pgAthleticsDisabilityCategory: string;
  pgAthleticsEvent1: string;
  pgAthleticsEvent2: string;
  pgAthleticsFourTimesOneHundredMetersRelay: boolean;
  pgSwimmingFourTimesTwentyFiveMetersBreaststroke: boolean;
  pgSwimmingFourTimesTwentyFiveMetersFreestyle: boolean;
  pgSwimmingEvent: string;
  pgSwimmingReserve: boolean;
  swimmingEvent: string;
  swimmingReserve: boolean;
  swimmingMembershipNumber: string;
  swimmingEntryTime: string;
  swimmingEntryTimeCourse: string;
  swimmingCompetitionEvent: string;
  swimmingRecreationalEvent: string;
  swimmingRecreationalEventNumber: string;
  swimmingRecreationalAgeGroup: string;
  swimmingRecreationalEventSex: string;
  swimmingRecreationalLevel: string;
  swimmingCompetitiveEvent: string;
  swimmingCompetitiveEventNumber: string;
  swimmingCompetitiveAgeGroup: string;
  swimmingCompetitiveEventSex: string;
  swimmingCompetitiveLevel: string;
  swimmingCompetitiveRegistrationNumber: string;
  swimmingCompetitiveEntryTime: string;
  swimmingCompetitiveEntryTimeCourse: string;
  swimmingParaSwimmingClassification: string;
  swimmingFourTimesFiftyMetersMedley: boolean;
  swimmingFourTimesFiftyMetersFreestyle: boolean;
  tennisMembershipNumber: string;
  tennisItfWorldTennisNumber: string;
  tennisSeniority: string;
  trampolineReserve: boolean;
  trampolineTier: string;
  trampolineMembershipNumber: string;
  crossCountryAgeCategory: string;
  roadCyclingMembershipNumber: string;
  roadCyclingTimeTrialCompetitor: boolean;
  roadCyclingRoadRaceCompetitor: boolean;
  roadCyclingTimeTrialCategory: string;
  roadCyclingReserve: boolean;
  entryId: number;
}

interface UploadResponse {
  lyg_school_game_entry_id: number;
}

async function uploadOpenGamesEntries(
  request: uploadOpenGamesEntriesRequest,
  token: AccessToken,
): Promise<UploadResponse> {
  const data = new FormData();
  if (request.name) data.append('lyg_name', request.name);
  if (request.gender) data.append('lyg_gender', request.gender);
  if (request.email) data.append('lyg_email', request.email);
  if (request.parentName) data.append('lyg_parent_name', request.parentName);
  if (request.parentEmail) data.append('lyg_parent_email', request.parentEmail);
  if (request.dob) data.append('lyg_dob', dateToISONoTimezone(request.dob));
  if (request.ethnicity) data.append('lyg_ethnicity', request.ethnicity);
  if (request.disability) data.append('lyg_disability', request.disability);
  if (request.homePostCode) data.append('lyg_home_postcode', request.homePostCode);
  if (request.school) data.append('lyg_school', request.school);
  //participant data
  if (request.emergencyContactName) data.append('lyg_emergency_contact_name', request.emergencyContactName);
  if (request.emergencyContactNumber) data.append('lyg_emergency_contact_number', request.emergencyContactNumber);
  data.append('lyg_imagery_consent', request.imageryConsent ? '1' : '0');
  if (request.aquathlonReserve) data.append('lyg_aquathlon_reserve', request.aquathlonReserve ? '1' : '0');
  if (request.athleticsTrackEvent) data.append('lyg_athletics_track_event', request.athleticsTrackEvent);
  if (request.athleticsFieldEvent) data.append('lyg_athletics_field_event', request.athleticsFieldEvent);
  if (request.athleticsFourTimesOneHundredMetersRelay)
    data.append(
      'lyg_athletics_four_times_one_hundred_meters_relay',
      request.athleticsFourTimesOneHundredMetersRelay ? '1' : '0',
    );
  if (request.emergencyContactNumber) data.append('lyg_bmx_license_number', request.emergencyContactNumber);
  if (request.bmxGrade) data.append('lyg_bmx_grade', request.bmxGrade);
  data.append('lyg_bmx_reserve', request.aquathlonReserve ? '1' : '0');
  if (request.indoorRowingReserve) data.append('lyg_indoor_rowing_reserve', request.indoorRowingReserve ? '1' : '0');
  if (request.indoorRowingCategoryAgeGroup) data.append('lyg_indoor_rowing_year_group', request.athleticsTrackEvent);
  if (request.judoLicenseNumber) data.append('lyg_judo_license_number', request.judoLicenseNumber);
  if (request.judoSection) data.append('lyg_judo_section', request.judoSection);
  if (request.judoDanGrade) data.append('lyg_judo_dan_grade', request.judoDanGrade);
  if (request.judoWeightBoysSectionA) data.append('lyg_judo_boys_section_a_weight', request.judoWeightBoysSectionA);
  if (request.judoWeightBoysSectionB) data.append('lyg_judo_boys_section_b_weight', request.judoWeightBoysSectionB);
  if (request.judoWeightBoysSectionC) data.append('lyg_judo_boys_section_c_weight', request.judoWeightBoysSectionC);
  if (request.judoWeightGirlsSectionA) data.append('lyg_judo_girls_section_a_weight', request.judoWeightGirlsSectionA);
  if (request.judoWeightGirlsSectionB) data.append('lyg_judo_girls_section_b_weight', request.judoWeightGirlsSectionB);
  if (request.judoWeightGirlsSectionC) data.append('lyg_judo_girls_section_c_weight', request.judoWeightGirlsSectionC);
  if (request.kayakSlalomSeniority) data.append('lyg_kayak_slalom_seniority', request.kayakSlalomSeniority);
  if (request.kayakSprintAgeCategory) data.append('lyg_kayak_sprint_age_category', request.kayakSprintAgeCategory);
  data.append('lyg_kayak_sprint_reserve', request.kayakSprintReserve ? '1' : '0');
  if (request.pgAthleticsDisabilityCategory)
    data.append('lyg_pg_athletics_disability_category', request.pgAthleticsDisabilityCategory);
  if (request.pgAthleticsEvent1) data.append('lyg_pg_athletics_event_1', request.pgAthleticsEvent1);
  if (request.pgAthleticsEvent2) data.append('lyg_pg_athletics_event_2', request.pgAthleticsEvent2);
  data.append(
    'lyg_pg_athletics_four_times_one_hundred_meters_relay',
    request.pgAthleticsFourTimesOneHundredMetersRelay ? '1' : '0',
  );
  data.append('lyg_pg_swimming_reserve', request.pgSwimmingReserve ? '1' : '0');
  data.append(
    'lyg_pg_swimming_four_times_twenty_five_meters_freestyle',
    request.pgSwimmingFourTimesTwentyFiveMetersFreestyle ? '1' : '0',
  );
  data.append(
    'lyg_pg_swimming_four_times_twenty_five_meters_backstroke',
    request.pgSwimmingFourTimesTwentyFiveMetersBreaststroke ? '1' : '0',
  );
  data.append('lyg_swimming_reserve', request.swimmingReserve ? '1' : '0');
  if (request.pgSwimmingEvent) data.append('lyg_pg_swimming_event', request.swimmingEntryTime);
  if (request.swimmingMembershipNumber) data.append('lyg_swimming_membership_number', request.swimmingMembershipNumber);
  if (request.swimmingEntryTime) data.append('lyg_swimming_entry_time', request.swimmingEntryTime);
  if (request.swimmingEvent) data.append('lyg_swimming_event', request.swimmingEntryTime);
  if (request.swimmingEntryTimeCourse) data.append('lyg_swimming_entry_time_course', request.swimmingEntryTimeCourse);
  if (request.swimmingCompetitionEvent) data.append('lyg_swimming_competition_event', request.swimmingCompetitionEvent);
  if (request.swimmingRecreationalEvent)
    data.append('lyg_swimming_recreational_event', request.swimmingRecreationalEvent);
  if (request.swimmingRecreationalEventNumber)
    data.append('lyg_swimming_recreational_event_number', request.swimmingRecreationalEventNumber);
  if (request.swimmingRecreationalAgeGroup)
    data.append('lyg_swimming_recreational_age_group', request.swimmingRecreationalAgeGroup);
  if (request.swimmingRecreationalEventSex)
    data.append('lyg_swimming_recreational_event_sex', request.swimmingRecreationalEventSex);
  if (request.swimmingRecreationalLevel)
    data.append('lyg_swimming_recreational_level', request.swimmingRecreationalLevel);
  if (request.swimmingCompetitiveEvent) data.append('lyg_swimming_competitive_event', request.swimmingCompetitiveEvent);
  if (request.swimmingCompetitiveEventNumber)
    data.append('lyg_swimming_competitive_event_number', request.swimmingCompetitiveEventNumber);
  if (request.swimmingCompetitiveAgeGroup)
    data.append('lyg_swimming_competitive_age_group', request.swimmingCompetitiveAgeGroup);
  if (request.swimmingCompetitiveEventSex)
    data.append('lyg_swimming_competitive_event_sex', request.swimmingCompetitiveEventSex);
  if (request.swimmingCompetitiveLevel) data.append('lyg_swimming_competitive_level', request.swimmingCompetitiveLevel);
  if (request.swimmingCompetitiveRegistrationNumber)
    data.append('lyg_swimming_competitive_registration_number', request.swimmingCompetitiveRegistrationNumber);
  if (request.swimmingCompetitiveEntryTime)
    data.append('lyg_swimming_competitive_entry_time', request.swimmingCompetitiveEntryTime);
  if (request.swimmingCompetitiveEntryTimeCourse)
    data.append('lyg_swimming_competitive_entry_time_course', request.swimmingCompetitiveEntryTimeCourse);

  if (request.swimmingParaSwimmingClassification)
    data.append('lyg_swimming_para_swimming_classification', request.swimmingParaSwimmingClassification);
  data.append('swimming_four_times_fifty_meters_medley', request.swimmingFourTimesFiftyMetersMedley ? '1' : '0');
  data.append('swimming_four_times_fifty_meters_freestyle', request.swimmingFourTimesFiftyMetersFreestyle ? '1' : '0');
  if (request.tennisMembershipNumber) data.append('lyg_tennis_membership_number', request.tennisMembershipNumber);
  if (request.tennisItfWorldTennisNumber)
    data.append('lyg_tennis_itf_world_tennis_number', request.tennisItfWorldTennisNumber);
  if (request.tennisSeniority) data.append('lyg_tennis_seniority', request.tennisSeniority);
  data.append('lyg_trampoline_reserve', request.trampolineReserve ? '1' : '0');
  if (request.trampolineTier) data.append('lyg_trampoline_tier', request.trampolineTier);
  if (request.trampolineMembershipNumber)
    data.append('lyg_trampoline_membership_number', request.trampolineMembershipNumber);
  if (request.borough) data.append('lyg_borough_id', request.borough);
  if (request.crossCountryAgeCategory)
    data.append('lyg_cross_country_event_age_category', request.crossCountryAgeCategory);
  if (request.roadCyclingMembershipNumber)
    data.append('lyg_road_cycling_membership_number', request.roadCyclingMembershipNumber);
  data.append('lyg_road_cycling_time_trial_competitor', request.roadCyclingTimeTrialCompetitor ? '1' : '0');
  data.append('lyg_road_cycling_road_race_competitor', request.roadCyclingRoadRaceCompetitor ? '1' : '0');
  if (request.roadCyclingTimeTrialCategory)
    data.append('lyg_road_cycling_time_trial_category', request.roadCyclingTimeTrialCategory);
  data.append('lyg_road_cycling_reserve', request.roadCyclingReserve ? '1' : '0');

  return apiClient(`/v1/lyg/entries/${request.entryId}`, {
    method: 'POST',
    token,
    body: data,
  });
}

export function useUploadLYGOpenGamesEntriesMutation(formModel: LYGEntryOpenGamesModel, entryId: number) {
  const queryClient = useQueryClient();
  const { token } = useAuthContext();

  const mutation = useMutation<UploadResponse, Error, void>(
    async () => await uploadOpenGamesEntries({ ...formModel.data, entryId }, token),
    {
      onSuccess() {
        queryClient.invalidateQueries(LYG_ENTRIES_KEYS.base);
      },
    },
  );

  return {
    isSuccess: mutation.isSuccess,
    isLoading: mutation.isLoading,
    execute: mutation.mutate,
  };
}
