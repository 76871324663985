import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useI18nContext } from 'src/context/i18n';
import { useLYGAppRoutesContext } from 'src/context/routes';
import { LYGEntryForm } from 'src/lyg/pages/entries/shared/LYGEntryForm';
import { LYGEntryOpenGamesModel } from 'src/lyg/pages/entries/shared/LYGOpenGamesModel';
import { useLYGOpenGameEntry, useUploadLYGOpenGamesEntriesMutation } from 'src/lyg/pages/entries/shared/entryQuery';
import { PrimaryButton } from 'src/shared/ui/buttons';
import { AuthenticatedPage } from 'src/shared/ui/layout/AuthenticatedPage';

export const EditOpenGamesEntryPage: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const routes = useLYGAppRoutesContext();
  const entryId = Number(params.entryId);
  const entryQuery = useLYGOpenGameEntry(entryId);
  const i18n = useI18nContext();
  const [model] = React.useState(new LYGEntryOpenGamesModel({ i18n: i18n }));
  const { execute, isLoading } = useUploadLYGOpenGamesEntriesMutation(model, entryQuery.data?.id ?? NaN);
  const onSuccess = () => navigate(routes.entries.base);

  React.useEffect(() => {
    model.setDefaultValues(entryQuery.data);
  }, [entryQuery.data, model]);

  return (
    <AuthenticatedPage title={entryQuery.data?.name}>
      {() => (
        <>
          <LYGEntryForm model={model} />
          <PrimaryButton className='mt-3 w-100' onClick={() => execute(undefined, { onSuccess })} loading={isLoading}>
            Save
          </PrimaryButton>
        </>
      )}
    </AuthenticatedPage>
  );
};
