import React from 'react';
import { cx } from 'src/shared/utils/common';
import { HeaderUserAvatar } from 'src/features/appLayout/ui/HeaderUserAvatar';
import { AppNotifications } from 'src/features/appLayout/ui/AppNotifications';
import { Breadcrumb } from 'src/features/appLayout/ui/PageHeader/Breadcrumb';
import { useIsMobile } from 'src/shared/hooks/useIsMobile';
import { CSS_BREAKPOINTS } from 'src/constants';
import css from './index.module.scss';

interface PageHeaderProps {
  title?: string;
  routeState?: unknown;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ children, title, routeState }) => {
  const isMobile = useIsMobile(CSS_BREAKPOINTS.lg);

  return isMobile ? (
    <div className='my-3' />
  ) : (
    <div className={cx('d-flex align-items-center justify-content-between', css.pageHeader)}>
      <Breadcrumb dynamicName={title} routeState={routeState} />

      <div className={cx('d-flex align-items-center', css.controls)}>
        <AppNotifications />
        <HeaderUserAvatar />
      </div>
      <div className='d-lg-none'>{children}</div>
    </div>
  );
};
