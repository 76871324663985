import React from 'react';
import { BoroughPicker } from 'src/lyg/features/boroughPicker';
import { SchoolPicker } from 'src/lyg/features/schoolPicker';
import { LYGEntryOpenGamesModel } from 'src/lyg/pages/entries/shared/LYGOpenGamesModel';
import { FormFieldWrapper } from 'src/shared/ui/inputs/FormFieldWrapper';
import { ToggleField } from 'src/shared/ui/inputs/boolean/ToggleField';
import { DateField } from 'src/shared/ui/inputs/datetime/DateField';
import { DropdownField } from 'src/shared/ui/inputs/dropdown/DropdownField';
import { TextField } from 'src/shared/ui/inputs/text/TextField';
import { Card, CardTitle } from 'src/shared/ui/widgets/Card';

interface LYGEntryFormProps {
  model: LYGEntryOpenGamesModel;
}

export const LYGEntryForm: React.FC<LYGEntryFormProps> = ({ model }) => {
  return (
    <>
      <CardTitle className='mb-3'>General Info</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.nameField}>
            <TextField model={model.nameField} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.genderField}>
            <DropdownField model={model.genderField} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.parentNameField}>
            <TextField model={model.parentNameField} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.parentEmailField}>
            <TextField model={model.parentEmailField} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.ethnicityField}>
            <TextField model={model.ethnicityField} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.disabilityField}>
            <TextField model={model.disabilityField} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.dobField}>
            <DateField model={model.dobField} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.homePostCodeField}>
            <TextField model={model.homePostCodeField} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.boroughPicker}>
            <BoroughPicker model={model.boroughPicker} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.schoolPicker}>
            <SchoolPicker model={model.schoolPicker} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.emergencyContactNumber}>
            <TextField model={model.emergencyContactNumber} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.emergencyContactName}>
            <TextField model={model.emergencyContactName} />
          </FormFieldWrapper>
        </div>
        <FormFieldWrapper model={model.imageryConsent}>
          <ToggleField model={model.imageryConsent} />
        </FormFieldWrapper>
      </Card>
      <CardTitle className='mb-3'>Aquathlon</CardTitle>
      <Card className='mb-2'>
        <FormFieldWrapper model={model.aquathlonReserve}>
          <ToggleField model={model.aquathlonReserve} />
        </FormFieldWrapper>
      </Card>
      <CardTitle className='mb-3'>Athletics</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.athleticsTrackEvent}>
            <TextField model={model.athleticsTrackEvent} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.athleticsFieldEvent}>
            <TextField model={model.athleticsFieldEvent} />
          </FormFieldWrapper>
        </div>
        <FormFieldWrapper model={model.athleticsFourTimesOneHundredMetersRelay}>
          <ToggleField model={model.athleticsFourTimesOneHundredMetersRelay} />
        </FormFieldWrapper>
      </Card>
      <CardTitle className='mb-3'>BMX</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.bmxLicenseNumber}>
            <TextField model={model.bmxLicenseNumber} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.bmxGrade}>
            <TextField model={model.bmxGrade} />
          </FormFieldWrapper>
        </div>
        <FormFieldWrapper model={model.bmxReserve}>
          <ToggleField model={model.bmxReserve} />
        </FormFieldWrapper>
      </Card>
      <CardTitle className='mb-3'>Indoor Rowing</CardTitle>
      <Card className='mb-2'>
        <FormFieldWrapper model={model.indoorRowingReserve}>
          <ToggleField model={model.indoorRowingReserve} />
        </FormFieldWrapper>
      </Card>
      <CardTitle className='mb-3'>Judo</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.judoLicenseNumber}>
            <TextField model={model.judoLicenseNumber} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.judoSection}>
            <TextField model={model.judoSection} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.judoDanGrade}>
            <TextField model={model.judoDanGrade} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.judoWeightBoysSectionA}>
            <TextField model={model.judoWeightBoysSectionA} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.judoWeightBoysSectionB}>
            <TextField model={model.judoWeightBoysSectionB} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.judoWeightBoysSectionC}>
            <TextField model={model.judoWeightBoysSectionC} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.judoWeightGirlsSectionA}>
            <TextField model={model.judoWeightGirlsSectionA} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.judoWeightGirlsSectionB}>
            <TextField model={model.judoWeightGirlsSectionB} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.judoWeightGirlsSectionC}>
            <TextField model={model.judoWeightGirlsSectionC} />
          </FormFieldWrapper>
        </div>
      </Card>
      <CardTitle className='mb-3'>Kayak</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.kayakSlalomSeniority}>
            <TextField model={model.kayakSlalomSeniority} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.kayakSprintAgeCategory}>
            <TextField model={model.kayakSprintAgeCategory} />
          </FormFieldWrapper>
        </div>
        <FormFieldWrapper model={model.kayakSprintReserve}>
          <ToggleField model={model.kayakSprintReserve} />
        </FormFieldWrapper>
      </Card>
      <CardTitle className='mb-3'>PG Athletics</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.pgAthleticsDisabilityCategory}>
            <TextField model={model.pgAthleticsDisabilityCategory} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.pgAthleticsEvent1}>
            <TextField model={model.pgAthleticsEvent1} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.pgAthleticsEvent2}>
            <TextField model={model.pgAthleticsEvent2} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.pgAthleticsFourTimesOneHundredMetersRelay}>
            <ToggleField model={model.pgAthleticsFourTimesOneHundredMetersRelay} />
          </FormFieldWrapper>
        </div>
      </Card>
      <CardTitle className='mb-3'>PG Swimming</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.pgSwimmingEvent}>
            <TextField model={model.pgSwimmingEvent} />
          </FormFieldWrapper>
          <FormFieldWrapper
            className='ms-3 w-100 flex-grow-1'
            model={model.pgSwimmingFourTimesTwentyFiveMetersBreaststroke}
          >
            <ToggleField model={model.pgSwimmingFourTimesTwentyFiveMetersBreaststroke} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.pgSwimmingReserve}>
            <ToggleField model={model.pgSwimmingReserve} />
          </FormFieldWrapper>
          <FormFieldWrapper
            className='ms-3 w-100 flex-grow-1'
            model={model.pgSwimmingFourTimesTwentyFiveMetersFreestyle}
          >
            <ToggleField model={model.pgSwimmingFourTimesTwentyFiveMetersFreestyle} />
          </FormFieldWrapper>
        </div>
      </Card>
      <CardTitle className='mb-3'>Swimming</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingMembershipNumber}>
            <TextField model={model.swimmingMembershipNumber} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingEntryTime}>
            <TextField model={model.swimmingEntryTime} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingEntryTimeCourse}>
            <TextField model={model.swimmingEntryTimeCourse} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingCompetitionEvent}>
            <TextField model={model.swimmingCompetitionEvent} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingParaSwimmingClassification}>
            <TextField model={model.swimmingParaSwimmingClassification} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingReserve}>
            <ToggleField model={model.swimmingReserve} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingEvent}>
            <TextField model={model.swimmingEvent} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingFourTimesFiftyMetersMedley}>
            <ToggleField model={model.swimmingFourTimesFiftyMetersMedley} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingFourTimesFiftyMetersFreestyle}>
            <ToggleField model={model.swimmingFourTimesFiftyMetersFreestyle} />
          </FormFieldWrapper>
        </div>
      </Card>
      <CardTitle className='mb-3'>Swimming Recreational</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingRecreationalEventNumber}>
            <TextField model={model.swimmingRecreationalEventNumber} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingRecreationalEvent}>
            <TextField model={model.swimmingRecreationalEvent} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingRecreationalEventSex}>
            <TextField model={model.swimmingRecreationalEventSex} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingRecreationalAgeGroup}>
            <TextField model={model.swimmingRecreationalAgeGroup} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingRecreationalLevel}>
            <TextField model={model.swimmingRecreationalLevel} />
          </FormFieldWrapper>
        </div>
      </Card>
      <CardTitle className='mb-3'>Swimming Competitive</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingCompetitiveEventNumber}>
            <TextField model={model.swimmingCompetitiveEventNumber} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingCompetitiveEvent}>
            <TextField model={model.swimmingCompetitiveEvent} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingCompetitiveEventSex}>
            <TextField model={model.swimmingCompetitiveEventSex} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingCompetitiveAgeGroup}>
            <TextField model={model.swimmingCompetitiveAgeGroup} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingCompetitiveEntryTime}>
            <TextField model={model.swimmingCompetitiveEntryTime} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingCompetitiveEntryTimeCourse}>
            <TextField model={model.swimmingCompetitiveEntryTimeCourse} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.swimmingCompetitiveRegistrationNumber}>
            <TextField model={model.swimmingCompetitiveRegistrationNumber} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.swimmingCompetitiveLevel}>
            <TextField model={model.swimmingCompetitiveLevel} />
          </FormFieldWrapper>
        </div>
      </Card>
      <CardTitle className='mb-3'>Tennis</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.tennisMembershipNumber}>
            <TextField model={model.tennisMembershipNumber} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.tennisItfWorldTennisNumber}>
            <TextField model={model.tennisItfWorldTennisNumber} />
          </FormFieldWrapper>
        </div>
        <FormFieldWrapper model={model.tennisSeniority}>
          <TextField model={model.tennisSeniority} />
        </FormFieldWrapper>
      </Card>
      <CardTitle className='mb-3'>Trampoline</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.trampolineMembershipNumber}>
            <TextField model={model.trampolineMembershipNumber} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.trampolineTier}>
            <TextField model={model.trampolineTier} />
          </FormFieldWrapper>
        </div>
        <FormFieldWrapper model={model.trampolineReserve}>
          <ToggleField model={model.trampolineReserve} />
        </FormFieldWrapper>
      </Card>
      <CardTitle className='mb-3'>Cross Country</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.crossCountryAgeCategory}>
            <TextField model={model.crossCountryAgeCategory} />
          </FormFieldWrapper>
        </div>
      </Card>
      <CardTitle className='mb-3'>Road Cycling</CardTitle>
      <Card className='mb-2'>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.roadCyclingMembershipNumber}>
            <TextField model={model.roadCyclingMembershipNumber} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.roadCyclingTimeTrialCategory}>
            <TextField model={model.roadCyclingTimeTrialCategory} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.roadCyclingTimeTrialCompetitor}>
            <ToggleField model={model.roadCyclingTimeTrialCompetitor} />
          </FormFieldWrapper>
          <FormFieldWrapper className='ms-3 w-100 flex-grow-1' model={model.roadCyclingRoadRaceCompetitor}>
            <ToggleField model={model.roadCyclingRoadRaceCompetitor} />
          </FormFieldWrapper>
        </div>
        <div className='d-flex'>
          <FormFieldWrapper className='w-100 flex-grow-1' model={model.roadCyclingReserve}>
            <ToggleField model={model.roadCyclingReserve} />
          </FormFieldWrapper>
        </div>
      </Card>
    </>
  );
};
