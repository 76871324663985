import { reaction } from 'mobx';
import React, { useEffect } from 'react';
import { useLYGBoroughsQuery } from 'src/lyg/entities/boroughs/query';
import { DropdownField } from 'src/shared/ui/inputs/dropdown/DropdownField';
import { DROPDOWN_BLANK_VALUE, DropdownFieldModel } from 'src/shared/ui/inputs/dropdown/DropdownFieldModel';

interface BoroughPickerProps {
  model: DropdownFieldModel;
  className?: string;
}

export const BoroughPicker: React.FC<BoroughPickerProps> = ({ model, className }) => {
  const { data: boroughs } = useLYGBoroughsQuery();

  useEffect(() => {
    if (!boroughs) return;
    model.setOptions(boroughs.map(({ id, name }) => ({ id: id.toString(), label: name })));
  }, [model, boroughs]);

  useEffect(() => {
    reaction(
      () => model.value.id,
      () => model.setSearchValue(''),
    );
  }, [model]);

  return <DropdownField model={model} className={className} isSmall />;
};

export function createBoroughPickerDropdown(props: RequireMinOne<Partial<DropdownFieldModel['props']>, 'i18n'>) {
  return new DropdownFieldModel({
    id: 'borough',
    initialValue: props.initialValue ?? DROPDOWN_BLANK_VALUE,
    title: 'Borough',
    withSearchbox: true,
    localSearchDisabled: false,
    withEmptyOption: false,
    required: true,
    ...props,
    i18n: props.i18n,
  });
}
