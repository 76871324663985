import 'src/styles/main.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import { FullScreenSpinner } from 'src/shared/ui/layout/FullScreenSpinner';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';

import { router } from 'src/routes';
import { lygRouter } from 'src/lyg/routes';
import { LYG_SUBDOMAIN } from 'src/context/routes';

if (import.meta.env.PROD && (window.location.protocol === 'http:' || window.location.protocol === 'http')) {
  window.location.href = window.location.href.replace('http://', 'https://');
}

Sentry.init({
  dsn: 'https://849f23a2870648f289ef550011871c5b@o1278815.ingest.sentry.io/6478660',
  integrations: [new BrowserTracing()],
  release: `coaches-corner@${import.meta.env.VITE_RELEASE}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control..
  tracesSampleRate: 1.0,
  environment: import.meta.env.MODE,
});

ReactGA.initialize('G-7BR0YZG6Y0', { testMode: import.meta.env.DEV });

const rootNode = document.getElementById('root');
const isLYGPage = window.location.hostname.startsWith(import.meta.env.PROD ? LYG_SUBDOMAIN : 'test');

ReactDOM.render(
  <RouterProvider router={isLYGPage ? lygRouter : router} fallbackElement={<FullScreenSpinner />} />,
  rootNode,
);
