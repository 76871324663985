import React from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import { RenderIfCurrentUserIs } from 'src/context/authContext/RenderIfCurrentUserIs';
import { APP_ROUTES } from 'src/context/routes';
import { AuthRoute, AuthRouteWithoutNav, CommonRoute } from 'src/RoutesHelpers';
import { UnauthorisedPage } from 'src/shared/ui/layout/UnauthorisedPage';
import { sendGAPageView } from 'src/shared/hooks/useAnalytics';
import { ErrorPage } from 'src/shared/ui/utils/ErrorPage';
import { load } from 'src/shared/utils/components';

const DashboardPage = () => load(import('src/pages/dashboard').then(m => ({ default: m.DashboardPage })));
const CreateCoachPage = () => load(import('src/pages/coaches/create').then(m => ({ default: m.CreateCoachPage })));
const CoachesHomePage = () => load(import('src/pages/coaches/home').then(m => ({ default: m.CoachesHomePage })));
const CoachProfilePage = () => load(import('src/pages/coaches/coach').then(m => ({ default: m.CoachProfilePage })));
const EditCoachPage = () => load(import('src/pages/coaches/edit').then(m => ({ default: m.EditCoachPage })));
const ClubsHomePage = () => load(import('src/pages/clubs/home').then(m => ({ default: m.ClubsHomePage })));
const ClubSettingsPage = () => load(import('src/pages/clubs/settings').then(m => ({ default: m.ClubSettingsPage })));
const CreateClubPage = () => load(import('src/pages/clubs/create').then(m => ({ default: m.CreateClubPage })));
const EditClubPage = () => load(import('src/pages/clubs/edit').then(m => ({ default: m.EditClubPage })));
const EventsHomePage = () => load(import('src/pages/events/home').then(m => ({ default: m.EventsHomePage })));
const EventsHomePageCompetition = () =>
  load(
    import('src/pages/events/home').then(m => ({ default: () => <m.EventsHomePage isCompetition></m.EventsHomePage> })),
  );
const CreateEventPage = () => load(import('src/pages/events/create').then(m => ({ default: m.CreateEventPage })));
const CreateEventCompetitionPage = () =>
  load(import('src/pages/events/create/competition').then(m => ({ default: m.CreateEventCompetitionPage })));
const EventPage = () => load(import('src/pages/events/eventPage').then(m => ({ default: m.EventPage })));
const EditEventPage = () => load(import('src/pages/events/edit').then(m => ({ default: m.EditEventPage })));
const EditEventCompetitionPage = () =>
  load(import('src/pages/events/edit/competition').then(m => ({ default: m.EditEventCompetitionPage })));
const UserSettingsPage = () => load(import('src/pages/settings').then(m => ({ default: m.UserSettingsPage })));
const OnboardingPage = () => load(import('src/pages/onboarding').then(m => ({ default: m.OnboardingPage })));
const BillingPage = () => load(import('src/pages/billing').then(m => ({ default: m.BillingPage })));
const SignInPage = () => load(import('src/pages/auth/signIn').then(m => ({ default: m.SignInPage })));
const SignUpPage = () => load(import('src/pages/auth/signUp').then(m => ({ default: m.SignUpPage })));
const ResetPasswordPage = () =>
  load(import('src/pages/auth/resetPassword').then(m => ({ default: m.ResetPasswordPage })));
const UpdatePasswordPage = () =>
  load(import('src/pages/auth/updatePassword').then(m => ({ default: m.UpdatePasswordPage })));
const NotFoundPage = () => load(import('src/pages/404').then(m => ({ default: m.NotFoundPage })));
const PlayersListPage = () => load(import('src/pages/players/list').then(m => ({ default: m.PlayersListPage })));
const PlayerProfilePage = () => load(import('src/pages/players/player').then(m => ({ default: m.PlayerProfilePage })));
const EditPlayerPage = () => load(import('src/pages/players/edit').then(m => ({ default: m.EditPlayerPage })));
const CreatePlayerPage = () => load(import('src/pages/players/list').then(m => ({ default: m.PlayersListPage })));
const TrainingStatsPage = () => load(import('src/pages/stats/training').then(m => ({ default: m.TrainingStatsPage })));
const GameStatsPage = () => load(import('src/pages/stats/game/gamePage').then(m => ({ default: m.GameStatsPage })));
const AllGameStatsPage = () => load(import('src/pages/stats/game/home').then(m => ({ default: m.AllGameStatsPage })));
const TargetsPage = () => load(import('src/pages/targets/home').then(m => ({ default: m.TargetsPage })));
const EditTargetPage = () => load(import('src/pages/targets/edit').then(m => ({ default: m.EditTargetPage })));
const CreateTargetPage = () => load(import('src/pages/targets/create').then(m => ({ default: m.CreateTargetPage })));
const TeamsHomePage = () => load(import('src/pages/teams/home').then(m => ({ default: m.TeamsHomePage })));
const CreateTeamPage = () => load(import('src/pages/teams/create').then(m => ({ default: m.CreateTeamPage })));
const TeamPage = () => load(import('src/pages/teams/team').then(m => ({ default: m.TeamPage })));
const ProductPage = () => load(import('src/pages/payments/payment').then(m => ({ default: m.ProductPage })));
const EditTeamPage = () => load(import('src/pages/teams/edit').then(m => ({ default: m.EditTeamPage })));
const FAQsPage = () => load(import('src/pages/support/faqs').then(m => ({ default: m.FAQsPage })));
const FeedbackPage = () => load(import('src/pages/support/feedback').then(m => ({ default: m.FeedbackPage })));
const ExercisesHomePage = () => load(import('src/pages/exercises/home').then(m => ({ default: m.ExercisesHomePage })));
const CreateExercisePage = () =>
  load(import('src/pages/exercises/create').then(m => ({ default: m.CreateExercisePage })));
const EditExercisePage = () => load(import('src/pages/exercises/edit').then(m => ({ default: m.EditExercisePage })));
const ExerciseConfigurationPage = () =>
  load(import('src/pages/exercises/configure').then(m => ({ default: m.ExerciseConfigurationPage })));
const VideosHomePage = () => load(import('src/pages/videos/home').then(m => ({ default: m.VideosHomePage })));
const CreateVideoPage = () => load(import('src/pages/videos/create').then(m => ({ default: m.CreateVideoPage })));
const EditVideoPage = () => load(import('src/pages/videos/edit').then(m => ({ default: m.EditVideoPage })));
const VideoPage = () => load(import('src/pages/videos/video').then(m => ({ default: m.VideoPage })));
const HighlightsPage = () => load(import('src/pages/highlights/home').then(m => ({ default: m.HighlightsPage })));
const ShareHighlightPage = () =>
  load(import('src/pages/highlights/share').then(m => ({ default: m.ShareHighlightPage })));
const EditHighlightPage = () => load(import('src/pages/highlights/edit').then(m => ({ default: m.EditHighlightPage })));
const CreateHighlightPage = () =>
  load(import('src/pages/highlights/archive').then(m => ({ default: m.CreateHighlightPage })));
const EventReportPage = () =>
  load(import('src/pages/events/eventPage/EventReportPage').then(m => ({ default: m.EventReportPage })));
const ReportsPage = () => load(import('src/pages/reports').then(m => ({ default: m.ReportsPage })));
const ReportPrintPage = () =>
  load(import('src/pages/reports/ReportPrintPage').then(m => ({ default: m.ReportPrintPage })));
const TeamInvitePrintPage = () =>
  load(import('src/pages/teams/print/InvitePrintPage').then(m => ({ default: m.InvitePrintPage })));
const PaymentsListPage = () => load(import('src/pages/payments/list').then(m => ({ default: m.PaymentsListPage })));
const ProductsCreatePage = () =>
  load(import('src/pages/payments/create').then(m => ({ default: m.ProductsCreatePage })));
const ProductsEditPage = () => load(import('src/pages/payments/edit').then(m => ({ default: m.ProductsEditPage })));
const PaymentsSetupPage = () => load(import('src/pages/payments/setup').then(m => ({ default: m.PaymentsSetupPage })));
const BulkImportFixturesPage = () =>
  load(import('src/pages/events/import').then(m => ({ default: m.BulkImportFixturesPage })));
const ChatPage = () => load(import('src/pages/chat').then(m => ({ default: m.ChatPage })));
const EntriesHomePage = () =>
  load(import('src/pages/competitionEntries/home').then(m => ({ default: m.EntriesHomePage })));
const EditEntriesPage = () =>
  load(import('src/pages/competitionEntries/entry').then(m => ({ default: m.EditEntryPage })));
const CreateEntriesPage = () =>
  load(import('src/pages/competitionEntries/create').then(m => ({ default: m.CreateEntryPage })));
const CompetitionPoolsPage = () =>
  load(import('src/pages/competitions/pools').then(m => ({ default: m.CompetitionPoolsPage })));
const CompetitionSchedulePage = () =>
  load(import('src/pages/competitions/schedule').then(m => ({ default: m.CompetitionSchedulePage })));
const CompetitionBracketPage = () =>
  load(import('src/pages/competitions/bracket').then(m => ({ default: m.CompetitionBracketPage })));
const CompetitionGamePage = () =>
  load(import('src/pages/competitions/game').then(m => ({ default: m.CompetitionGamePage })));
const CompetitionLiveGameEditorPage = () =>
  load(import('src/pages/competitions/game/liveEditor').then(m => ({ default: m.CompetitionLiveGameEditorPage })));
const CompetitionGameScoreBoard = () =>
  load(import('src/pages/competitions/game/scoreBoard').then(m => ({ default: m.CompetitionGameScoreBoard })));
const CompetitionGameShotClock = () =>
  load(import('src/pages/competitions/game/shotClock').then(m => ({ default: m.CompetitionGameShotClock })));
const MembershipView = () =>
  load(import('src/pages/membership/membershipView').then(m => ({ default: m.MembershipView })));
const ClubWebSiteConfig = () => load(import('src/pages/clubWebSite').then(m => ({ default: m.ClubWebSiteConfig })));
const TeamsportzLocations = () => load(import('src/pages/locations').then(m => ({ default: m.TeamsportzLocations })));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <CommonRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: APP_ROUTES.auth.signIn,
        element: <SignInPage />,
        loader: sendGAPageView,
      },
      {
        path: APP_ROUTES.auth.signUp,
        element: <SignUpPage />,
        loader: sendGAPageView,
      },
      {
        path: APP_ROUTES.auth.resetPassword,
        element: <ResetPasswordPage />,
        loader: sendGAPageView,
      },
      {
        path: APP_ROUTES.auth.updatePassword,
        element: <UpdatePasswordPage />,
        loader: sendGAPageView,
      },
      {
        path: 'score/:gameId',
        element: <CompetitionGameScoreBoard />,
        loader: sendGAPageView,
      },
      {
        path: 'shotclock/:gameId',
        element: <CompetitionGameShotClock />,
        loader: sendGAPageView,
      },
      {
        path: 'locations',
        element: <TeamsportzLocations />,
        loader: sendGAPageView,
      },
      {
        element: <AuthRoute />,
        children: [
          {
            path: APP_ROUTES.dashboard,
            element: <DashboardPage />,
            loader: sendGAPageView,
          },
          /* CHAT */
          {
            path: APP_ROUTES.chat.base,
            element: <ChatPage />,
            loader: sendGAPageView,
          },
          /* COACHES */
          {
            path: APP_ROUTES.coaches.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CoachesHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':coachId',
                element: <CoachProfilePage />,
                loader: sendGAPageView,
              },
              {
                path: ':coachId/edit',
                element: <EditCoachPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: <CreateCoachPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* CLUBS */
          {
            path: APP_ROUTES.clubs.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <ClubsHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':clubId/settings',
                element: <ClubSettingsPage />,
                loader: sendGAPageView,
              },
              {
                path: ':clubId/edit',
                element: <EditClubPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: <CreateClubPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* EVENTS */
          {
            path: APP_ROUTES.events.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <EventsHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':eventId',
                element: <Outlet />,
                loader: sendGAPageView,
                children: [
                  {
                    index: true,
                    element: <EventPage />,
                    loader: sendGAPageView,
                  },
                  {
                    path: 'competition',
                    element: <Outlet />,
                    children: [
                      {
                        path: ':competitionId/pools',
                        element: <CompetitionPoolsPage />,
                        loader: sendGAPageView,
                      },
                      {
                        path: ':competitionId/schedule',
                        element: <CompetitionSchedulePage />,
                        loader: sendGAPageView,
                      },
                      {
                        path: ':competitionId/bracket',
                        element: <CompetitionBracketPage />,
                        loader: sendGAPageView,
                      },
                      {
                        path: ':competitionId/game/:gameId',
                        element: <CompetitionGamePage />,
                        loader: sendGAPageView,
                      },
                    ],
                  },
                ],
              },
              {
                path: ':eventId/edit',
                element: <EditEventPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: <CreateEventPage />,
                loader: sendGAPageView,
              },
              {
                path: 'import',
                element: <BulkImportFixturesPage />,
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: APP_ROUTES.events.competitions.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <EventsHomePageCompetition />,
                loader: sendGAPageView,
              },
              {
                path: ':eventId',
                element: <Outlet />,
                loader: sendGAPageView,
                children: [
                  {
                    index: true,
                    element: <EventPage />,
                    loader: sendGAPageView,
                  },
                  {
                    path: 'competition',
                    element: <Outlet />,
                    children: [
                      {
                        path: ':competitionId/pools',
                        element: <CompetitionPoolsPage />,
                        loader: sendGAPageView,
                      },
                      {
                        path: ':competitionId/schedule',
                        element: <CompetitionSchedulePage />,
                        loader: sendGAPageView,
                      },
                      {
                        path: ':competitionId/bracket',
                        element: <CompetitionBracketPage />,
                        loader: sendGAPageView,
                      },
                      {
                        path: ':competitionId/game/:gameId',
                        element: <CompetitionGamePage />,
                        loader: sendGAPageView,
                      },
                    ],
                  },
                ],
              },
              {
                path: ':eventId/edit',
                element: <EditEventCompetitionPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: <CreateEventCompetitionPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* EXERCISES */
          {
            path: APP_ROUTES.exercises.base,
            element: (
              <RenderIfCurrentUserIs admin elseRender={<UnauthorisedPage />}>
                <Outlet />
              </RenderIfCurrentUserIs>
            ),
            children: [
              {
                index: true,
                element: <ExercisesHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':exerciseId/edit',
                element: <EditExercisePage />,
                loader: sendGAPageView,
              },
              {
                path: ':exerciseId/configure',
                element: <ExerciseConfigurationPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: <CreateExercisePage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* PLAYERS */
          {
            path: APP_ROUTES.players.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <PlayersListPage />,
                loader: sendGAPageView,
              },
              {
                path: ':playerId',
                element: <PlayerProfilePage />,
                loader: sendGAPageView,
              },
              {
                path: ':playerId/edit',
                element: <EditPlayerPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: <CreatePlayerPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* REPORTS */
          {
            path: APP_ROUTES.reports.base,
            element: <ReportsPage />,
            loader: sendGAPageView,
          },
          /* STATS */
          {
            path: APP_ROUTES.trainingStats,
            element: <TrainingStatsPage />,
            loader: sendGAPageView,
          },
          {
            path: APP_ROUTES.gameStats.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <AllGameStatsPage />,
                loader: sendGAPageView,
              },
              {
                path: ':gameId',
                element: <GameStatsPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* SETTINGS */
          {
            path: APP_ROUTES.settings.base,
            element: <UserSettingsPage />,
            loader: sendGAPageView,
          },
          {
            path: APP_ROUTES.billing.base,
            element: <MembershipView />,
            loader: sendGAPageView,
          },
          {
            path: APP_ROUTES.clubWebsite.base,
            element: <ClubWebSiteConfig />,
            loader: sendGAPageView,
          },
          /* SUBSCRIPTIONS */
          {
            path: APP_ROUTES.products.base,
            element: <Outlet />,
            loader: sendGAPageView,
            children: [
              {
                index: true,
                element: <PaymentsListPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: <ProductsCreatePage />,
                loader: sendGAPageView,
              },
              {
                path: 'setup',
                element: <PaymentsSetupPage />,
                loader: sendGAPageView,
              },
              {
                path: ':productId',
                element: <ProductPage />,
                loader: sendGAPageView,
              },
              {
                path: ':productId/edit',
                element: <ProductsEditPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* SUPPORT */
          {
            path: APP_ROUTES.feedbackCentre,
            element: <FeedbackPage />,
            loader: sendGAPageView,
          },
          {
            path: APP_ROUTES.faqCentre,
            element: <FAQsPage />,
            loader: sendGAPageView,
          },
          /* TARGETS */
          {
            path: APP_ROUTES.targets.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <TargetsPage />,
                loader: sendGAPageView,
              },
              {
                path: ':targetId/edit',
                element: <EditTargetPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: <CreateTargetPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* TEAMS */
          {
            path: APP_ROUTES.teams.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <TeamsHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':teamId',
                element: <TeamPage />,
                loader: sendGAPageView,
              },
              {
                path: ':teamId/edit',
                element: <EditTeamPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: <CreateTeamPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* VIDEOS */
          {
            path: APP_ROUTES.videos.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <VideosHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':videoId',
                element: <VideoPage />,
                loader: sendGAPageView,
              },
              {
                path: ':videoId/edit',
                element: <EditVideoPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: <CreateVideoPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* VIDEO-CLIPS / HIGHLIGHTS */
          {
            path: APP_ROUTES.highlights.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CreateHighlightPage />,
                loader: sendGAPageView,
              },
              {
                path: 'archive',
                element: <HighlightsPage />,
                loader: sendGAPageView,
              },
              {
                path: 'share/:highlightsIds',
                element: <ShareHighlightPage />,
                loader: sendGAPageView,
              },
              {
                path: ':highlightId/edit',
                element: <EditHighlightPage />,
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: APP_ROUTES.entries.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <EntriesHomePage />,
                loader: sendGAPageView,
              },
              {
                index: true,
                path: ':entryId/edit',
                element: <EditEntriesPage />,
                loader: sendGAPageView,
              },
              {
                index: true,
                path: 'create',
                element: <CreateEntriesPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* 404 */
          {
            path: '*',
            element: <NotFoundPage />,
            loader: sendGAPageView,
          },
        ],
      },
      {
        element: <AuthRouteWithoutNav />,
        children: [
          {
            path: 'calendar/competitions/:eventId/competition/:competitionId/game/:gameId/live',
            element: <CompetitionLiveGameEditorPage />,
            loader: sendGAPageView,
          },
          {
            path: APP_ROUTES.onboarding,
            element: <OnboardingPage />,
            loader: sendGAPageView,
          },
          {
            path: APP_ROUTES.membership.base,
            element: <BillingPage />,
            loader: sendGAPageView,
          },
          {
            path: 'calendar/:eventId/report',
            element: <EventReportPage />,
            loader: sendGAPageView,
          },
          {
            path: 'reports/:reportType/:teamId/:period/print',
            element: <ReportPrintPage />,
            loader: sendGAPageView,
          },
          {
            path: 'teams/:teamId/invite',
            element: <TeamInvitePrintPage />,
            loader: sendGAPageView,
          },
        ],
      },
    ],
  },
]);
