import { apiClient } from 'src/shared/utils/apiClient';

export enum GET_INVITED_PLAYERS_SORT_BY {
  email = 'email',
  name = 'name',
  team = 'team_name',
}

interface GetInvitedPlayersRequest {
  filters?: {
    email?: string;
    name?: string;
    teamId?: number;
  };
  limit?: number;
  page?: number;
  sortAscending?: boolean;
  sortBy?: GET_INVITED_PLAYERS_SORT_BY;
  token: AccessToken;
}

interface GetInvitedPlayersResponse {
  data: {
    created_at: DateTimeString;
    invite_by: number;
    invite_email: string;
    invite_guardian_email: string | null;
    invite_id: number;
    invite_name: string;
    profile_first_name: string;
    team_id: number | null;
    team_name: string | null;
    asked_to_join_date: null;
    deleted_at: null;
    email: string | null;
    invite_first_name: string;
    invite_is_minor: 0 | 1;
    invite_last_name: string;
    invite_message: string | null;
    invite_status: string;
    org_id: number | null;
    profile_avatar: string | null;
    profile_id: number | null;
    profile_last_name: string;
    team_avatar: string | null;
    team_code: string | null;
    updated_at: DateTimeString;
    user_id: number;
  }[];
  total: number;
}

export async function getInvitedPlayers(request: GetInvitedPlayersRequest): Promise<GetInvitedPlayersResponse> {
  const filters: string[] = [];

  if (request.filters) {
    if (request.filters.email) filters.push(`email=${request.filters.email}`);
    if (request.filters.name) filters.push(`name=${request.filters.name}`);
    if (request.filters.teamId) filters.push(`team_id=${request.filters.teamId}`);
  }
  if (request.limit) filters.push(`limit=${request.limit}`);
  if (request.page) filters.push(`page=${request.page}`);
  if (request.sortAscending !== undefined) filters.push(`sorting=${request.sortAscending ? 'asc' : 'desc'}`);
  if (request.sortBy) filters.push(`sort_name=${request.sortBy}`);

  const queryString = filters.length > 0 ? `?${filters.join('&')}` : '';

  return apiClient(`/invites${queryString}`, { token: request.token });
}
