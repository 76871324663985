import { action, makeObservable, observable } from 'mobx';
import { InputFieldModel, InputFieldModelProps } from 'src/shared/ui/inputs/InputFieldModel';
import { getNonRefValue } from 'src/shared/utils/common';
import { dateIsValid, getMonthDates, StartEndDates } from 'src/shared/utils/dates';

type DateFieldValue = Date | null;

interface DateFieldModelProps extends InputFieldModelProps<DateFieldValue> {
  minDate?: Date;
  maxDate?: Date;
  excludedDates?: Date[];
  helperText?: string;
}

export class DateFieldModel extends InputFieldModel<DateFieldValue, DateFieldModelProps> {
  currentMonthView: StartEndDates;
  excludedDates: Date[];

  constructor(props: DateFieldModelProps) {
    super(props);

    this.currentMonthView = getMonthDates(props.initialValue || new Date());
    this.excludedDates = props.excludedDates || [];

    makeObservable(this, {
      currentMonthView: observable,
      excludedDates: observable.ref,
      setCurrentMonthView: action.bound,
      setExcludedDates: action.bound,
    });
  }

  get hasChanged(): boolean {
    if (dateIsValid(this.value) && dateIsValid(this.defaultValue)) {
      return this.value.getTime() !== this.defaultValue.getTime();
    }

    return this.value !== this.defaultValue;
  }

  get minDate(): Date | undefined {
    return this.props.minDate;
  }

  get maxDate(): Date | undefined {
    return this.props.maxDate;
  }

  get helperText(): string {
    return this.props.helperText || '';
  }

  protected get requiredIsValid(): boolean {
    return dateIsValid(this.value);
  }

  setCurrentMonthView(date: Date): void {
    this.currentMonthView = getMonthDates(date);
  }

  setValue(value: DateFieldValue): void {
    this.value = getNonRefValue(value);
    value && this.setCurrentMonthView(value);
  }

  setDefaultValue(defaultValue: DateFieldValue): void {
    this.value = getNonRefValue(defaultValue);
    this.defaultValue = getNonRefValue(defaultValue);
    defaultValue && this.setCurrentMonthView(defaultValue);
  }

  setExcludedDates(dates: Date[]): void {
    this.excludedDates = dates;
  }
}
